// import { ChainId } from '@uniswap/sdk'
import React, { useEffect, useState } from 'react'
// import { isMobile } from 'react-device-detect'
import { Text } from 'rebass'
import i18next from 'i18next'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

// import LogoDark from '../../assets/svg/logo_white.svg'
// import Wordmark from '../../assets/svg/wordmark.svg'
// import WordmarkDark from '../../assets/svg/wordmark_white.svg'
import { useActiveWeb3React } from '../../hooks'
import { useDarkModeManager } from '../../state/user/hooks'
import { useETHBalances } from '../../state/wallet/hooks'

// import { YellowCard } from '../Card'
import Settings from '../Settings'
// import Menu from '../Menu'

// import Row, { AutoRow, RowBetween } from '../Row'
import Web3Status from '../Web3Status'
// import VersionSwitch from './VersionSwitch'
import { langList, switchPageTitleAndDesc } from '../../i18n'
import chainSucessIcon from '../../assets/images/chain-sucess-icon.png'

import './header.css'

const HeaderFrame = styled.div<{ isDark: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 150px;
  height: 74px;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 2;
  background-color: ${({ theme, isDark }) => (isDark ? 'transparent' : '#fff')};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 0 15px;
    height: 54px;
    position: relative;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  text-decoration: none;

  :hover {
    cursor: pointer;
  }
`

/* const TitleText = styled(Row)`
  width: fit-content;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
` */

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bgAccount)};
  border-radius: 12px;
  height: 38px;
  white-space: nowrap;
  width: 100%;

  :focus {
    border: 1px solid blue;
  }
`
/* 
const TestnetWrapper = styled.div`
  white-space: nowrap;
  width: fit-content;
  margin-left: 10px;
  pointer-events: auto;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const NetworkCard = styled(YellowCard)`
  width: fit-content;
  margin-right: 10px;
  border-radius: 12px;
  padding: 8px 12px;
`
 */
const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const LogoImg = styled.img`
  display: block;
  height: 43px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    height: 29px;
  `};
`
const LangDiv = styled.div`
  height: 74px;
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 40px;
  @media screen and (max-width: 768px) {
    height: 54px;
    margin: 0 10px;
    position: static;
  }
`
const LangImg = styled.img<{ isDark: boolean }>`
  display: block;
  height: 20px;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    width: 16px;
    height: 16px;
  }
  filter: ${({ theme, isDark }) => (isDark ? 'brightness(100)' : '')};
`
const LangLn = styled.ul`
  border-radius: 10px;
  background-color: #fff;
  padding: 10px 0;
  width: 120px;
  z-index: 100;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
  position: absolute;
  top: 44px;
  left: 50%;
  transition: all 0.3s;
  @media screen and (max-width: 768px) {
    top: 29px;
    left: auto;
    right: 15px;
  }
`
const LangLi = styled.li`
  height: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: #333333;
  @media screen and (max-width: 768px) {
    padding: 0 10px;
    font-size: 13px;
  }
`
const LangLiImg = styled.img`
  display: block;
  width: 12px;
  margin-left: auto;
`
/* const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
  [ChainId.MAINNET]: null,
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Görli',
  [ChainId.KOVAN]: 'Kovan',
  [ChainId.ZXY]: process.env.REACT_APP_CHAIN_NAME as string
} */

export default function Header() {
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()

  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  const [isDark] = useDarkModeManager()

  const [isShowLang, setIsShowLang] = useState(false)
  const [acLang, setAcLang] = useState(localStorage.getItem('i18nextLng') || 'en')

  const langChange = (lang: string) => {
    i18next.changeLanguage(lang)
    setAcLang(lang)
    setIsShowLang(false)
    switchPageTitleAndDesc(lang)
  }
  useEffect(() => {
    const newLang: string = localStorage.getItem('i18nextLng') || 'en'
    setAcLang(newLang)
  }, [])

  return (
    <HeaderFrame isDark={isDark}>
      <HeaderElement>
        <Title href="https://www.novaichain.com">
          {isDark ? (
            <LogoImg src="/images/home/logo_dark.svg" alt="logo" />
          ) : (
            <LogoImg src="/images/home/logo.svg" alt="logo" />
          )}
        </Title>
      </HeaderElement>
      <HeaderControls>
        <HeaderElementWrap>
          {/* <VersionSwitch /> */}
          <Settings />
          {/* <Menu /> */}
        </HeaderElementWrap>
        <HeaderElement>
          <LangDiv onMouseEnter={() => setIsShowLang(true)} onMouseLeave={() => setIsShowLang(false)}>
            <LangImg isDark={isDark} src="/images/home/lang.png" onClick={() => setIsShowLang(true)} />
            <LangLn className={isShowLang ? 'dropdown_item' : 'dropdown_items'}>
              {langList.map((item, i) => (
                <LangLi
                  key={i}
                  onClick={() => langChange(item.value)}
                  className={item.value === acLang ? 'lang_active' : ''}
                >
                  {item.name}
                  {item.value === acLang && <LangLiImg src="/images/home/correct.png" />}
                </LangLi>
              ))}
            </LangLn>
          </LangDiv>
          {/* <TestnetWrapper>
            {!isMobile && chainId && NETWORK_LABELS[chainId] && <NetworkCard>{NETWORK_LABELS[chainId]}</NetworkCard>}
          </TestnetWrapper> */}
          <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
            {account && userEthBalance ? (
              <BalanceText style={{ flexShrink: 0 }} px="10px" fontWeight={500}>
                {userEthBalance?.toFixed(4, undefined, 1)} {process.env.REACT_APP_CHAIN_SYMBOL}
              </BalanceText>
            ) : null}
            <Web3Status />
            <div id="switch-chain-tip">
              <img alt="switch chain tip icon" className="switch-chain-tip-icon" src={chainSucessIcon} />
              {t('SwitchNetWrokSuccess')}
            </div>
          </AccountElement>
        </HeaderElement>
      </HeaderControls>
    </HeaderFrame>
  )
}
